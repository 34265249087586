<template>
  <div>
    <breadcrumb name="Roadside Assistance" title="Settings" subtitle="Emails & Hotlines"/>
    <div class="body">
      <h6>Emails</h6>
      <vs-row v-for="(item, index) in data" :key="index" style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="item.rsa_emails" class="input" size="medium" :label="item.brand_name"/>
        </vs-col>
      </vs-row>
      <vs-divider/>

      <h6>Hotlines</h6>
      <vs-row style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="hotlines.rsa_call_number" class="input" label="First RSA Call Number" type="number"/>
        </vs-col>
      </vs-row>

      <vs-row style="margin: 20px">
        <vs-col vs-lg="11" vs-sm="12" class="settings">
          <vs-input v-model="hotlines['2d_rsa_call_number']" class="input" label="Second RSA Call Number"
                    type="number"/>
        </vs-col>
      </vs-row>
      <div class="optionBtns">
        <vs-button
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          :disabled="invalidForm"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="getBrands"
        >Cancel
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    components: {Breadcrumb},
    data() {
      return {
        setting: [],
        brands: [],
        data: [],
        hotlines: {},
        line1: "",
        line2: "",
        invalidEmails: false
      };
    },
    methods: {
      getHotlines() {
        this.$vs.loading();
        this.$httpAdmin.get("config/rsa")
          .then(r => {
            this.$vs.loading.close();
            this.hotlines = r.data.data;
            //console.log({hotlines: this.hotlines});
            //console.log(r);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      async saveEmails() {
        let data = [];
        //console.log({data: data});
        for (let i in this.data) {
          data.push({});
          data[i].rsa_emails = this.data[i].rsa_emails.split(",");
          data[i].brand_id = this.data[i].brand_id;

          for (let x in data[i].rsa_emails) {
            data[i].rsa_emails[x] = data[i].rsa_emails[x].trim();
          }

          for (let x in data[i].rsa_emails) {
            if (!this.validateEmail(data[i].rsa_emails[x])) {
              this.$vs.notify({
                title: "Error",
                text: "There is an invalid email found.\nPlease, check all emails again",
                color: "danger"
              });
              this.invalidEmails = true;
              return false;
            }
          }

          this.invalidEmails = false;

          try {
            await this.$httpAdmin.post("brand-configs/update", data[i]);
          } catch (e) {
            //console.log({e});
          }
        }
        console.log(data);
      },
      async saveData() {
        this.$vs.loading();
        try {
          await this.saveEmails();

          if (this.invalidEmails) {
            this.$vs.loading.close();
            return;
          } else {
            await this.$httpAdmin.post("config/rsa", this.hotlines);
          }

        } catch (e) {

          this.$vs.loading.close();
          //console.log(e);
          this.$vs.notify({
            title: "Error",
            text: "Error is occurred!\nCouldn't Save Data!",
            color: "danger"
          });
        }
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Saved",
          text: "Data is Saved Successfully",
          color: "primary"
        });
        this.getBrands();
      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars
          .get(`/brands`)
          .then(res => {
            this.$vs.loading.close();
            this.brands = res.data.data.brands;

            //console.log({brands: this.brands});

            this.$vs.loading();
            this.$httpAdmin
              .get(`/brand-configs`)
              .then(res => {
                this.$vs.loading.close();
                this.settings = res.data.data;

                //console.log({settings: this.settings});

                let b = this.brands;
                this.data = [];

                for (let x in this.settings) {
                  for (let i in this.brands) {
                    if (this.brands[i].id === this.settings[x].brand_id) {
                      this.data.push(this.settings[x]);
                      b.splice(i, 1);
                    }
                  }
                }

                for (let i in b) {
                  this.data.push({
                    brand_id: b[i].id,
                    brand_name: b[i].name_en,
                    rsa_emails: [],
                    booking_service_emails: []
                  });
                }

                for (let i in this.data) {
                  this.data[i].rsa_emails = this.data[i].rsa_emails.toString();
                }

                //console.log({data: this.data});
              })
              .catch(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Something Error",
                  text: "Couldn't fetch data from the server",
                  color: "danger"
                });
              });


          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't fetch data from the server",
              color: "danger"
            });
          });
      },
    },
    computed: {
      invalidForm() {
        for (let i in this.data) {
          if (this.data[i].rsa_emails.length === 0) {
            return true;
          }
        }

        if (this.hotlines['2d_rsa_call_number'] === '' || this.hotlines.rsa_call_number === '') {
          return true;
        }

        return false;
      }
    },
    watch: {},
    mounted() {
      this.getBrands();
      this.getHotlines();
    }
  };
</script>

<style scoped lang="scss">
  .body {
    border-radius: 10px;
    padding: 20px;
  }

  .optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input {
    width: 100%;
    margin: 0 !important;
  }
</style>
